@layer base {
	:root {
		--base-margin: 1rem;
		--base-link-font-size: 0.875rem;
		--base-font-size: 1.1rem;
		--back-to-top-size: 2.5rem;
		--back-to-top-margin-size: 1rem;
		--base-figure-width: 40rem;
		--base-figure-border: solid var(--main-color) 0.3rem;
		--base-figure-padding: 1.5rem;
		--body-max-inline-size: 67.5rem;
		--heading-link-font: "ChiayiCity", open-huninn, "Noto Sans CJK", system-ui;

		/* theme color https://coolors.co/000000-ffffff-a40e4c-4357ad-8c4843 */
		--main-color: hsl(0deg 0% 0%);
		--secondary-color: hsl(0deg 0% 100%);
		--active-color: hsl(335deg 84% 35%);
		--link-color: #0047ab;
		--action-color: #b5a642;
		--text-color: hsl(0deg 0% 20%);

		@media (width >= 667px) {
			--base-margin: 3rem;
		}
	}

	:focus-visible:not(body) {
		outline: thick double black;
		outline-color: var(--active-color);
		outline-offset: 0.1rem;
	}

	@font-face {
		font-family: ChiayiCity;
		font-weight: bold;
		src:
			local("Chiayi City Font"),
			url("/_assets/fonts/ChiayiCity.woff2");
		font-display: swap;
	}

	@font-face {
		font-family: open-huninn;
		src:
			local("jf-openhuninn-2.0"),
			url("/_assets/fonts/jf-openhuninn.woff2");
		font-display: swap;
	}

	html {
		color: var(--text-color);
		font-family: open-huninn, "Noto Sans CJK", system-ui;
	}

	body {
		max-inline-size: var(--body-max-inline-size);
		margin-block-end: calc(
			var(--back-to-top-size) + var(--back-to-top-margin-size) + 1rem
		);
		margin-inline: auto;
		color: var(--text-color);

		&:focus-visible {
			outline-offset: -0.5rem;
		}
	}

	a {
		color: var(--link-color);
		font-family: var(--heading-link-font);
		font-weight: 700;
	}

	:is(h1, h2, h3, h4, h5) {
		display: block flex;
		justify-content: center;
		margin: 0.8rem 0;
		margin-block-end: 1.5rem;
		color: var(--text-color);
		font-family: var(--heading-link-font);
		scroll-margin-block-start: 0.5rem;

		bilingual-text {
			display: block flex;
			flex-direction: column;
			inline-size: fit-content;

			:not([lang="nan-latn-TW"]) {
				text-align: center;
			}
		}

		a {
			font-size: revert;
		}
	}

	p {
		font-size: var(--base-font-size);
		line-height: 1.5rlh;

		&:has(bilingual-text) {
			display: block flex;
			flex-direction: column;
			gap: 0.6rem;

			@media (width >= 667px) {
				--split-line-width: 0.0313rem;

				background: linear-gradient(
					90deg,
					var(--secondary-color) 0%,
					var(--secondary-color) calc(50% - var(--split-line-width)),
					var(--text-color) calc(50% - var(--split-line-width)),
					var(--text-color) calc(50% + var(--split-line-width)),
					var(--secondary-color) calc(50% + var(--split-line-width)),
					var(--secondary-color) 100%
				);
			}
		}

		bilingual-text {
			display: block flex;
			flex-direction: column;

			@media (width >= 667px) {
				display: block grid;
				grid-auto-flow: dense;
				grid-template-columns: 1fr 1fr;
				column-gap: 2.5rem;

				span[lang="nan-latn-TW"] {
					grid-column: 2 / -1;
				}

				span:not([lang="nan-latn-TW"]) {
					grid-column: 1 / 2;
				}
			}
		}
	}

	:is(blockquote, figure) {
		line-height: 1.2rlh;
	}

	footer:has(.address-block) {
		border-block-start: 1px solid var(--text-color);
		margin-block-start: 3rem;
		margin-inline: var(--base-margin);
		padding-block-start: 1rem;
	}
}

@layer component {
	@keyframes loading-rotate {
		from {
			rotate: 0;
		}

		to {
			rotate: 360deg;
		}
	}

	.skip-link {
		position: absolute;
		padding: 1rem;
		border: 0.15rem solid var(--active-color);
		background-color: var(--secondary-color);
		inset-block-start: 0;
		inset-inline-start: 50%;
		translate: -50% -100%;

		&:focus {
			translate: -50% 100%;
		}
	}

	.back-to-top-link {
		display: block flex;
		position: fixed;
		align-items: center;
		justify-content: center;
		block-size: var(--back-to-top-size);
		inline-size: var(--back-to-top-size);
		background-color: var(--link-color);
		inset-block-end: 1rem;
		inset-inline-end: 1rem;

		& .back-to-top-link-icon {
			margin: 0.5rem;
			color: var(--secondary-color);
		}
	}

	.back-to-top-link-kbd-container {
		display: none;
		position: absolute;
		align-items: center;
		justify-content: center;
		padding: 0.3rem 0.4rem;
		border: 1px solid var(--link-color);
		background-color: var(--secondary-color);
		color: var(--link-color);
		font-size: 0.8rem;
		line-height: 0.5rlh;
		inset-inline-start: 50%;
		inset-block-start: 0;
		translate: -50% calc(-100% - 0.3rem);
		gap: 0.1rem;
	}

	/* stylelint-disable-next-line csstools/use-nesting */
	body:focus-within .back-to-top-link-kbd-container {
		display: block flex;
	}

	.back-to-top-link-kbd-item {
		white-space: nowrap;
	}

	.site-nav {
		ul {
			display: block flex;
			gap: 0.5rem;
			justify-content: center;
			margin-block: 0.2rem;
		}

		li {
			padding-block-end: 0.1875rem;
			border-block-end: 1px solid var(--link-color);
		}

		a {
			font-size: var(--base-link-font-size);
			text-decoration: none;

			bilingual-text {
				display: block flex;
				flex-direction: column;
				gap: 0;
			}
		}
	}

	.post-hashtag {
		display: inline grid;
		grid-template:
			"hashtag nan" auto
			"hashtag zh " auto / auto auto;
		max-inline-size: 70vw;
		padding-inline-end: 0.1rem;
		border: 0.1rem solid var(--link-color);
		font-size: var(--base-link-font-size);
		text-decoration: none;
		gap: 0.08rem;

		.hashtag {
			display: block flex;
			grid-area: hashtag;
			align-items: center;
			align-self: stretch;
			margin-inline-end: 0.3rem;
			padding: 0.3rem;
			background-color: var(--link-color);
			color: var(--secondary-color);
		}

		bilingual-text {
			display: block grid;
			grid-column: 1 / -1;
			grid-row: 1 / -1;
			grid-template-columns: subgrid;
			grid-template-rows: subgrid;
			margin-inline-end: 0.3rem;

			span {
				grid-column: 2 / 3;

				&[lang="nan-latn-TW"] {
					grid-row: 1 / 2;
				}

				&:not([lang="nan-latn-TW"]) {
					grid-row: 2 / 3;
				}
			}
		}
	}

	.address-block {
		--icon-container-padding: 0.4rem;
		--icon-container-width: calc(
			1rem + var(--icon-container-padding) * 2 + var(--icon-link-border-width)
		);
		--icon-link-border-width: 0.1rem;

		margin-inline: var(--base-margin);

		ul {
			display: block grid;
			grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
			justify-content: start;
			padding: 0;
			list-style: none;
			gap: 0.5rem;
		}

		li {
			display: inline grid;
			grid-template-columns: auto 1fr auto;
			align-items: center;
			justify-content: center;
			border: var(--icon-link-border-width) solid var(--link-color);
			gap: 0.3rem;
			font-family: var(--heading-link-font);

			span {
				grid-column: 2 / 3;
				grid-row: 1 / 2;
				justify-self: center;
				inline-size: fit-content;
				font-size: var(--base-link-font-size);
			}
		}

		a {
			display: block grid;
			grid-column: 1 / 4;
			grid-row: 1 / 2;
			grid-template-columns: subgrid;
			align-items: center;
			justify-content: center;
			color: var(--secondary-color);

			:is(.prefix-icon, .postfix-icon) {
				display: block flow;
				box-sizing: content-box;
				grid-row: 1 / 2;
				padding: var(--icon-container-padding);
			}

			.prefix-icon {
				grid-column: 1 / 2;
				background-color: var(--link-color);
			}

			.postfix-icon {
				grid-column: 3 / 4;
				color: var(--link-color);
			}
		}
	}
}

@layer page {
	.index-header {
		margin-inline: var(--base-margin);
	}

	.all-post-link {
		display: block flex;
		justify-content: center;
		inline-size: fit-content;
		margin-block: var(--base-margin);
		margin-inline: auto;

		bilingual-text {
			display: block flex;
			flex-direction: column;
			text-align: center;
		}
	}

	.post {
		margin-inline: var(--base-margin);

		.section-skip-link {
			inline-size: fit-content;
		}

		figure {
			--max-inline-size: 80vw;

			display: block flex;
			flex-direction: column;
			align-items: center;
			max-inline-size: var(--max-inline-size);
			margin-inline: auto;

			@media (width >= 667px) {
				--max-inline-size: 25rem;
			}

			&.margin-less-figure {
				--max-inline-size: 100%;
			}

			&.bordered-figure {
				padding: 0.2rem;
				padding-block-end: 1rem;
				border: solid 0.2rem var(--text-color);
			}

			ul {
				li {
					bilingual-text {
						display: block flex;
						flex-direction: column;
						inline-size: fit-content;
						max-inline-size: var(--max-inline-size);
					}
				}
			}

			img {
				block-size: auto;
				max-block-size: 100%;
				inline-size: auto;
				max-inline-size: 100%;
			}

			figcaption {
				display: block flex;
				flex-direction: column;
				max-inline-size: var(--max-inline-size);
				margin-block-start: 1rem;
				gap: 0.5rem;

				bilingual-text {
					display: block flex;
					flex-direction: column;
				}
			}
		}

		header {
			ul {
				display: block flex;
				flex-flow: row wrap;
				justify-content: center;
				padding: 0;
				list-style: none;
				gap: 0.8rem;
			}

			dl {
				display: block grid;
				grid-template-columns: auto 1fr;
				align-items: end;
				inline-size: fit-content;
				margin-inline: auto;
				font-weight: 700;
				gap: 1rem;

				dt {
					grid-column: 1 / 2;

					bilingual-text {
						display: block flex;
						flex-direction: column;
					}
				}

				dd {
					display: block flex;
					grid-column: 2 / 3;
					align-items: center;
					margin-inline-start: 0;
					gap: 0.5rem;
				}
			}
		}

		.toc-aside {
			display: block flex;
			flex-direction: column;
			inline-size: fit-content;
			margin-block: 1rem;
			margin-inline: auto;
			padding-inline: 1rem;
			border: 0.15rem solid var(--link-color);

			.section-skip-link {
				align-self: center;
			}

			:is(h1, h2, h3, h4, h5) {
				bilingual-text {
					display: block flex;
					flex-direction: column;
					text-align: revert;
				}
			}

			ul {
				padding-inline-start: revert;
				list-style: revert;

				li {
					a {
						display: inline flex;

						bilingual-text {
							display: inline flex;
							flex-direction: column;
						}
					}
				}
			}
		}

		footer {
			display: block flex;
			justify-content: center;

			a {
				text-align: center;

				bilingual-text {
					display: block flex;
					flex-direction: column;
				}
			}
		}

		.webmention {
			display: block flex;
			flex-direction: column;
			align-items: center;
			inline-size: fit-content;
			max-inline-size: 25rem;
			margin-inline: auto;
			border: 0.15rem solid var(--link-color);

			h2 {
				padding-inline: 1rem;
			}

			dl {
				display: block grid;
				grid-template:
					"like-icon like-count . reply-icon reply-count . repost-icon repost-count" auto / auto auto 1fr auto auto 1fr auto auto;
				align-items: center;
				inline-size: 100%;
				margin-block-end: 0.5rem;
				padding-block-start: 0.5rem;
				padding-inline: 1rem;
				border-block-start: 1px solid var(--text-color);

				dt {
					display: block flex;
					align-items: center;

					&.like-icon {
						grid-area: like-icon;
					}

					&.reply-icon {
						grid-area: reply-icon;
					}

					&.repost-icon {
						grid-area: repost-icon;
					}
				}

				dd {
					display: block flex;
					align-items: center;
					margin: 0.5rem;

					&.like-count {
						grid-area: like-count;
					}

					&.reply-count {
						grid-area: reply-count;
					}

					&.repost-count {
						grid-area: repost-count;
					}

					svg {
						animation-name: loading-rotate;
						animation-duration: 1s;
						animation-iteration-count: infinite;
					}
				}
			}

			a {
				align-self: center;
				margin-block-end: 0;
				padding-inline: 1rem;

				bilingual-text {
					display: block flex;
					flex-direction: column;
					align-items: center;
					margin-block-end: 0.8rem;
				}
			}
		}

		:is(.previous-post-aside, .next-post-aside) {
			margin-block: 1.5rem;

			a {
				display: block flex;
				justify-content: center;
				inline-size: fit-content;
				margin-inline: auto;
				text-align: center;

				bilingual-text {
					display: block flex;
					flex-direction: column;
				}
			}
		}

		.content {
			blockquote {
				@media (width >= 667px) {
					--max-inline-size: 30rem;
				}

				display: block flow;
				max-inline-size: var(--max-inline-size);
				margin-inline: auto;
				padding-block: 1rem;
				padding-inline-start: 1rem;
				border-inline-start: 0.125rem solid var(--main-color);
			}

			& > ul {
				--gap: 0.5rem;

				inline-size: fit-content;
				max-inline-size: var(--max-inline-size);
				margin-inline: auto;

				@media (width >= 667px) {
					--max-inline-size: 25rem;
				}

				li {
					margin-block-end: var(--gap);

					a {
						display: inline flex;
					}

					bilingual-text {
						display: block flex;
						flex-direction: column;
					}

					ul {
						margin-block-start: var(--gap);
					}
				}
			}
		}

		.reference {
			display: block flex;
			flex-direction: column;

			a {
				align-self: center;
			}

			ul {
				display: block flow;
				inline-size: fit-content;
				margin-inline: auto;
				padding-inline-start: 1rem;

				li {
					inline-size: 100%;
				}
			}
		}
	}

	.index-section {
		margin-inline: var(--base-margin);

		.post {
			margin-inline: revert;
		}
	}

	.index-hashtag-list {
		display: block flex;
		flex-flow: row wrap;
		justify-content: center;
		padding: 0;
		list-style: none;
		gap: 0.8rem;
	}

	.search-form {
		display: block grid;
		grid-template-columns: 1fr auto;
		grid-template-rows: auto auto;
		inline-size: fit-content;
		margin-inline: auto;
		gap: 1rem;

		label {
			grid-column: 1 / -1;
			grid-row: 1 / 2;
			font-weight: 700;

			bilingual-text {
				display: block flex;
				flex-direction: column;
			}
		}

		input {
			grid-column: 1 / 2;
			grid-row: 2 / 3;
			border: 0.13rem solid var(--link-color);
			border-radius: 0;
		}

		button {
			display: inline flex;
			grid-column: 2 / 3;
			grid-row: 2 / 3;
			align-items: center;
			padding: 0.2rem 0.5rem;
			border: 0.13rem solid var(--link-color);
			border-radius: 0;
			background-color: var(--link-color);
			color: var(--secondary-color);
			font-weight: 600;

			bilingual-text {
				display: block flex;
				flex-direction: column;
			}
		}
	}
}

@layer override {
	.sr-only {
		position: absolute;
		block-size: 1px;
		inline-size: 1px;
		margin: -0.0625rem;
		padding: 0;
		overflow: hidden;
		clip: rect(1px, 1px, 1px, 1px);
		border: 0;
		white-space: nowrap;
		clip-path: inset(50%);
	}

	/* 2024-03-08-problem-of-information-access-to-people-with-visual-impair */
	.braille-example {
		display: block flex;
		flex-direction: column;

		img {
			inline-size: 7.5rem;
		}
	}
}
