@layer reset {
	:where(:root) {
		line-height: revert;
		cursor: revert;

		@supports (cursor: revert-layer) {
			line-height: revert-layer;
			cursor: revert-layer;
		}
	}
}
