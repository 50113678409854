@layer reset {
  *, :before, :after {
    box-sizing: border-box;
    background-repeat: no-repeat;
  }

  :before, :after {
    -webkit-text-decoration: inherit;
    text-decoration: inherit;
    vertical-align: inherit;
  }

  :where(:root) {
    cursor: default;
    overflow-wrap: break-word;
    tab-size: 4;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-size-adjust: 100%;
    line-height: 1.5;
  }

  :where(body) {
    margin: 0;
  }

  :where(h1) {
    margin: .67em 0;
    font-size: 2em;
  }

  :where(dl, ol, ul) :where(dl, ol, ul) {
    margin: 0;
  }

  :where(hr) {
    color: inherit;
    height: 0;
  }

  :where(nav) :where(ol, ul) {
    padding: 0;
    list-style-type: none;
  }

  :where(nav li):before {
    content: "​";
    float: left;
  }

  :where(pre) {
    font-family: monospace;
    font-size: 1em;
    overflow: auto;
  }

  :where(abbr[title]) {
    text-decoration: underline dotted;
  }

  :where(b, strong) {
    font-weight: bolder;
  }

  :where(code, kbd, samp) {
    font-family: monospace;
    font-size: 1em;
  }

  :where(small) {
    font-size: 80%;
  }

  :where(audio, canvas, iframe, img, svg, video) {
    vertical-align: middle;
  }

  :where(iframe) {
    border-style: none;
  }

  :where(svg:not([fill])) {
    fill: currentColor;
  }

  :where(table) {
    border-collapse: collapse;
    text-indent: 0;
    border-color: currentColor;
  }

  :where(button, input, select) {
    margin: 0;
  }

  :where(button, [type="button" i], [type="reset" i], [type="submit" i]) {
    -webkit-appearance: button;
  }

  :where(fieldset) {
    border: 1px solid #a0a0a0;
  }

  :where(progress) {
    vertical-align: baseline;
  }

  :where(textarea) {
    resize: vertical;
    margin: 0;
  }

  :where([type="search" i]) {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  ::-webkit-inner-spin-button {
    height: auto;
  }

  ::-webkit-outer-spin-button {
    height: auto;
  }

  ::-webkit-input-placeholder {
    color: inherit;
    opacity: .54;
  }

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }

  :where(dialog) {
    color: #000;
    background-color: #fff;
    border: solid;
    width: fit-content;
    height: fit-content;
    margin: auto;
    padding: 1em;
    position: absolute;
    left: 0;
    right: 0;
  }

  :where(dialog:not([open])) {
    display: none;
  }

  :where(details > summary:first-of-type) {
    display: list-item;
  }

  :where([aria-busy="true" i]) {
    cursor: progress;
  }

  :where([aria-disabled="true" i], [disabled]) {
    cursor: not-allowed;
  }

  :where([aria-hidden="false" i][hidden]) {
    display: initial;
  }

  :where([aria-hidden="false" i][hidden]:not(:focus)) {
    clip: rect(0, 0, 0, 0);
    position: absolute;
  }

  :where(:root) {
    line-height: revert;
    cursor: revert;
  }

  @supports (cursor: revert-layer) {
    :where(:root) {
      line-height: revert-layer;
      cursor: revert-layer;
    }
  }
}

@layer theme;

@layer base {
  :root {
    --base-margin: 1rem;
    --base-link-font-size: .875rem;
    --base-font-size: 1.1rem;
    --back-to-top-size: 2.5rem;
    --back-to-top-margin-size: 1rem;
    --base-figure-width: 40rem;
    --base-figure-border: solid var(--main-color) .3rem;
    --base-figure-padding: 1.5rem;
    --body-max-inline-size: 67.5rem;
    --heading-link-font: "ChiayiCity", open-huninn, "Noto Sans CJK", system-ui;
    --main-color: #000;
    --secondary-color: #fff;
    --active-color: #a40e4d;
    --link-color: #0047ab;
    --action-color: #b5a642;
    --text-color: #333;
  }

  @media (width >= 667px) {
    :root {
      --base-margin: 3rem;
    }
  }

  :focus-visible:not(body) {
    outline: thick double #000;
    outline-color: var(--active-color);
    outline-offset: .1rem;
  }

  @font-face {
    font-family: ChiayiCity;
    font-weight: bold;
    src: local(Chiayi City Font), url("ChiayiCity.2fe4d1f9.woff2");
    font-display: swap;
  }

  @font-face {
    font-family: open-huninn;
    src: local(jf-openhuninn-2\.0), url("jf-openhuninn.1fa8139a.woff2");
    font-display: swap;
  }

  html {
    color: var(--text-color);
    font-family: open-huninn, Noto Sans CJK, system-ui;
  }

  body {
    max-inline-size: var(--body-max-inline-size);
    color: var(--text-color);
    margin-block-end: calc(var(--back-to-top-size)  + var(--back-to-top-margin-size)  + 1rem);
    margin-inline: auto;
  }

  body:focus-visible {
    outline-offset: -.5rem;
  }

  a {
    color: var(--link-color);
    font-family: var(--heading-link-font);
    font-weight: 700;
  }

  :is(h1, h2, h3, h4, h5) {
    color: var(--text-color);
    font-family: var(--heading-link-font);
    justify-content: center;
    margin: .8rem 0;
    margin-block-end: 1.5rem;
    scroll-margin-block-start: .5rem;
    display: flex;
  }

  :is(h1, h2, h3, h4, h5) bilingual-text {
    flex-direction: column;
    inline-size: fit-content;
    display: flex;
  }

  :is(h1, h2, h3, h4, h5) bilingual-text :not([lang="nan-latn-TW"]) {
    text-align: center;
  }

  :is(h1, h2, h3, h4, h5) a {
    font-size: revert;
  }

  p {
    font-size: var(--base-font-size);
    line-height: 1.5rlh;
  }

  p:has(bilingual-text) {
    flex-direction: column;
    gap: .6rem;
    display: flex;
  }

  @media (width >= 667px) {
    p:has(bilingual-text) {
      --split-line-width: .0313rem;
      background: linear-gradient(90deg, var(--secondary-color) 0%, var(--secondary-color) calc(50% - var(--split-line-width)), var(--text-color) calc(50% - var(--split-line-width)), var(--text-color) calc(50% + var(--split-line-width)), var(--secondary-color) calc(50% + var(--split-line-width)), var(--secondary-color) 100%);
    }
  }

  p bilingual-text {
    flex-direction: column;
    display: flex;
  }

  @media (width >= 667px) {
    p bilingual-text {
      grid-auto-flow: dense;
      grid-template-columns: 1fr 1fr;
      column-gap: 2.5rem;
      display: grid;
    }

    p bilingual-text span[lang="nan-latn-TW"] {
      grid-column: 2 / -1;
    }

    p bilingual-text span:not([lang="nan-latn-TW"]) {
      grid-column: 1 / 2;
    }
  }

  :is(blockquote, figure) {
    line-height: 1.2rlh;
  }

  footer:has(.address-block) {
    border-block-start: 1px solid var(--text-color);
    margin-block-start: 3rem;
    margin-inline: var(--base-margin);
    padding-block-start: 1rem;
  }
}

@layer component {
  @keyframes loading-rotate {
    from {
      rotate: 0;
    }

    to {
      rotate: 360deg;
    }
  }

  .skip-link {
    border: .15rem solid var(--active-color);
    background-color: var(--secondary-color);
    padding: 1rem;
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 50%;
    translate: -50% -100%;
  }

  .skip-link:focus {
    translate: -50% 100%;
  }

  .back-to-top-link {
    block-size: var(--back-to-top-size);
    inline-size: var(--back-to-top-size);
    background-color: var(--link-color);
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    inset-block-end: 1rem;
    inset-inline-end: 1rem;
  }

  .back-to-top-link .back-to-top-link-icon {
    color: var(--secondary-color);
    margin: .5rem;
  }

  .back-to-top-link-kbd-container {
    border: 1px solid var(--link-color);
    background-color: var(--secondary-color);
    color: var(--link-color);
    justify-content: center;
    align-items: center;
    gap: .1rem;
    padding: .3rem .4rem;
    font-size: .8rem;
    line-height: .5rlh;
    display: none;
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 50%;
    translate: -50% calc(-100% - .3rem);
  }

  body:focus-within .back-to-top-link-kbd-container {
    display: flex;
  }

  .back-to-top-link-kbd-item {
    white-space: nowrap;
  }

  .site-nav ul {
    justify-content: center;
    gap: .5rem;
    margin-block: .2rem;
    display: flex;
  }

  .site-nav li {
    border-block-end: 1px solid var(--link-color);
    padding-block-end: .1875rem;
  }

  .site-nav a {
    font-size: var(--base-link-font-size);
    text-decoration: none;
  }

  .site-nav a bilingual-text {
    flex-direction: column;
    gap: 0;
    display: flex;
  }

  .post-hashtag {
    border: .1rem solid var(--link-color);
    font-size: var(--base-link-font-size);
    grid-template: "hashtag nan"
                   "hashtag zh"
                   / auto auto;
    gap: .08rem;
    max-inline-size: 70vw;
    padding-inline-end: .1rem;
    text-decoration: none;
    display: inline-grid;
  }

  .post-hashtag .hashtag {
    background-color: var(--link-color);
    color: var(--secondary-color);
    grid-area: hashtag;
    align-self: stretch;
    align-items: center;
    margin-inline-end: .3rem;
    padding: .3rem;
    display: flex;
  }

  .post-hashtag bilingual-text {
    grid-area: 1 / 1 / -1 / -1;
    grid-template-columns: subgrid;
    grid-template-rows: subgrid;
    margin-inline-end: .3rem;
    display: grid;
  }

  .post-hashtag bilingual-text span {
    grid-column: 2 / 3;
  }

  .post-hashtag bilingual-text span[lang="nan-latn-TW"] {
    grid-row: 1 / 2;
  }

  .post-hashtag bilingual-text span:not([lang="nan-latn-TW"]) {
    grid-row: 2 / 3;
  }

  .address-block {
    --icon-container-padding: .4rem;
    --icon-container-width: calc(1rem + var(--icon-container-padding) * 2 + var(--icon-link-border-width));
    --icon-link-border-width: .1rem;
    margin-inline: var(--base-margin);
  }

  .address-block ul {
    grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
    justify-content: start;
    gap: .5rem;
    padding: 0;
    list-style: none;
    display: grid;
  }

  .address-block li {
    border: var(--icon-link-border-width) solid var(--link-color);
    font-family: var(--heading-link-font);
    grid-template-columns: auto 1fr auto;
    justify-content: center;
    align-items: center;
    gap: .3rem;
    display: inline-grid;
  }

  .address-block li span {
    font-size: var(--base-link-font-size);
    grid-area: 1 / 2 / 2 / 3;
    justify-self: center;
    inline-size: fit-content;
  }

  .address-block a {
    grid-area: 1 / 1 / 2 / 4;
    grid-template-columns: subgrid;
    color: var(--secondary-color);
    justify-content: center;
    align-items: center;
    display: grid;
  }

  .address-block a :is(.prefix-icon, .postfix-icon) {
    box-sizing: content-box;
    padding: var(--icon-container-padding);
    grid-row: 1 / 2;
    display: block;
  }

  .address-block a .prefix-icon {
    background-color: var(--link-color);
    grid-column: 1 / 2;
  }

  .address-block a .postfix-icon {
    color: var(--link-color);
    grid-column: 3 / 4;
  }
}

@layer page {
  .index-header {
    margin-inline: var(--base-margin);
  }

  .all-post-link {
    margin-block: var(--base-margin);
    justify-content: center;
    inline-size: fit-content;
    margin-inline: auto;
    display: flex;
  }

  .all-post-link bilingual-text {
    text-align: center;
    flex-direction: column;
    display: flex;
  }

  .post {
    margin-inline: var(--base-margin);
  }

  .post .section-skip-link {
    inline-size: fit-content;
  }

  .post figure {
    --max-inline-size: 80vw;
    max-inline-size: var(--max-inline-size);
    flex-direction: column;
    align-items: center;
    margin-inline: auto;
    display: flex;
  }

  @media (width >= 667px) {
    .post figure {
      --max-inline-size: 25rem;
    }
  }

  .post figure.margin-less-figure {
    --max-inline-size: 100%;
  }

  .post figure.bordered-figure {
    border: solid .2rem var(--text-color);
    padding: .2rem;
    padding-block-end: 1rem;
  }

  .post figure ul li bilingual-text {
    max-inline-size: var(--max-inline-size);
    flex-direction: column;
    inline-size: fit-content;
    display: flex;
  }

  .post figure img {
    block-size: auto;
    max-block-size: 100%;
    inline-size: auto;
    max-inline-size: 100%;
  }

  .post figure figcaption {
    max-inline-size: var(--max-inline-size);
    flex-direction: column;
    gap: .5rem;
    margin-block-start: 1rem;
    display: flex;
  }

  .post figure figcaption bilingual-text {
    flex-direction: column;
    display: flex;
  }

  .post header ul {
    flex-flow: wrap;
    justify-content: center;
    gap: .8rem;
    padding: 0;
    list-style: none;
    display: flex;
  }

  .post header dl {
    grid-template-columns: auto 1fr;
    align-items: end;
    gap: 1rem;
    inline-size: fit-content;
    margin-inline: auto;
    font-weight: 700;
    display: grid;
  }

  .post header dl dt {
    grid-column: 1 / 2;
  }

  .post header dl dt bilingual-text {
    flex-direction: column;
    display: flex;
  }

  .post header dl dd {
    grid-column: 2 / 3;
    align-items: center;
    gap: .5rem;
    margin-inline-start: 0;
    display: flex;
  }

  .post .toc-aside {
    border: .15rem solid var(--link-color);
    flex-direction: column;
    inline-size: fit-content;
    margin-block: 1rem;
    margin-inline: auto;
    padding-inline: 1rem;
    display: flex;
  }

  .post .toc-aside .section-skip-link {
    align-self: center;
  }

  .post .toc-aside :is(h1, h2, h3, h4, h5) bilingual-text {
    text-align: revert;
    flex-direction: column;
    display: flex;
  }

  .post .toc-aside ul {
    list-style: revert;
    padding-inline-start: revert;
  }

  .post .toc-aside ul li a {
    display: inline-flex;
  }

  .post .toc-aside ul li a bilingual-text {
    flex-direction: column;
    display: inline-flex;
  }

  .post footer {
    justify-content: center;
    display: flex;
  }

  .post footer a {
    text-align: center;
  }

  .post footer a bilingual-text {
    flex-direction: column;
    display: flex;
  }

  .post .webmention {
    border: .15rem solid var(--link-color);
    flex-direction: column;
    align-items: center;
    inline-size: fit-content;
    max-inline-size: 25rem;
    margin-inline: auto;
    display: flex;
  }

  .post .webmention h2 {
    padding-inline: 1rem;
  }

  .post .webmention dl {
    border-block-start: 1px solid var(--text-color);
    grid-template: "like-icon like-count . reply-icon reply-count . repost-icon repost-count"
    / auto auto 1fr auto auto 1fr auto auto;
    align-items: center;
    inline-size: 100%;
    margin-block-end: .5rem;
    padding-block-start: .5rem;
    padding-inline: 1rem;
    display: grid;
  }

  .post .webmention dl dt {
    align-items: center;
    display: flex;
  }

  .post .webmention dl dt.like-icon {
    grid-area: like-icon;
  }

  .post .webmention dl dt.reply-icon {
    grid-area: reply-icon;
  }

  .post .webmention dl dt.repost-icon {
    grid-area: repost-icon;
  }

  .post .webmention dl dd {
    align-items: center;
    margin: .5rem;
    display: flex;
  }

  .post .webmention dl dd.like-count {
    grid-area: like-count;
  }

  .post .webmention dl dd.reply-count {
    grid-area: reply-count;
  }

  .post .webmention dl dd.repost-count {
    grid-area: repost-count;
  }

  .post .webmention dl dd svg {
    animation-name: loading-rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  .post .webmention a {
    align-self: center;
    margin-block-end: 0;
    padding-inline: 1rem;
  }

  .post .webmention a bilingual-text {
    flex-direction: column;
    align-items: center;
    margin-block-end: .8rem;
    display: flex;
  }

  .post :is(.previous-post-aside, .next-post-aside) {
    margin-block: 1.5rem;
  }

  .post :is(.previous-post-aside, .next-post-aside) a {
    text-align: center;
    justify-content: center;
    inline-size: fit-content;
    margin-inline: auto;
    display: flex;
  }

  .post :is(.previous-post-aside, .next-post-aside) a bilingual-text {
    flex-direction: column;
    display: flex;
  }

  .post .content blockquote {
    max-inline-size: var(--max-inline-size);
    border-inline-start: .125rem solid var(--main-color);
    margin-inline: auto;
    padding-block: 1rem;
    padding-inline-start: 1rem;
    display: block;
  }

  @media (width >= 667px) {
    .post .content blockquote {
      --max-inline-size: 30rem;
    }
  }

  .post .content > ul {
    --gap: .5rem;
    max-inline-size: var(--max-inline-size);
    inline-size: fit-content;
    margin-inline: auto;
  }

  @media (width >= 667px) {
    .post .content > ul {
      --max-inline-size: 25rem;
    }
  }

  .post .content > ul li {
    margin-block-end: var(--gap);
  }

  .post .content > ul li a {
    display: inline-flex;
  }

  .post .content > ul li bilingual-text {
    flex-direction: column;
    display: flex;
  }

  .post .content > ul li ul {
    margin-block-start: var(--gap);
  }

  .post .reference {
    flex-direction: column;
    display: flex;
  }

  .post .reference a {
    align-self: center;
  }

  .post .reference ul {
    inline-size: fit-content;
    margin-inline: auto;
    padding-inline-start: 1rem;
    display: block;
  }

  .post .reference ul li {
    inline-size: 100%;
  }

  .index-section {
    margin-inline: var(--base-margin);
  }

  .index-section .post {
    margin-inline: revert;
  }

  .index-hashtag-list {
    flex-flow: wrap;
    justify-content: center;
    gap: .8rem;
    padding: 0;
    list-style: none;
    display: flex;
  }

  .search-form {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr auto;
    gap: 1rem;
    inline-size: fit-content;
    margin-inline: auto;
    display: grid;
  }

  .search-form label {
    grid-area: 1 / 1 / 2 / -1;
    font-weight: 700;
  }

  .search-form label bilingual-text {
    flex-direction: column;
    display: flex;
  }

  .search-form input {
    border: .13rem solid var(--link-color);
    border-radius: 0;
    grid-area: 2 / 1 / 3 / 2;
  }

  .search-form button {
    border: .13rem solid var(--link-color);
    background-color: var(--link-color);
    color: var(--secondary-color);
    border-radius: 0;
    grid-area: 2 / 2 / 3 / 3;
    align-items: center;
    padding: .2rem .5rem;
    font-weight: 600;
    display: inline-flex;
  }

  .search-form button bilingual-text {
    flex-direction: column;
    display: flex;
  }
}

@layer utility;

@layer override {
  .sr-only {
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
    clip-path: inset(50%);
    border: 0;
    block-size: 1px;
    inline-size: 1px;
    margin: -.0625rem;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .braille-example {
    flex-direction: column;
    display: flex;
  }

  .braille-example img {
    inline-size: 7.5rem;
  }
}
/*# sourceMappingURL=index.095a4455.css.map */
